import "flickity/dist/flickity.min.css";
import "plyr/dist/plyr.css";
import "../css/styles.scss";
import menuToggle from "./layout/menuToggle";
import mobileNavigation from "./layout/navigation";
import teamFiltering from "./components/team";
import pagePortal from "./components/page_portal";
import slideshow from "./components/slideshow";
import videoPlayer from "./components/video";
if (import.meta.env.MODE === 'development') {
  const elements = document.querySelectorAll('.component--label');
  elements.forEach(element => element.style.display = 'block');
}

const init = () => {
  menuToggle();
  mobileNavigation();
  teamFiltering();
  pagePortal();
  slideshow()
  videoPlayer();
}
console.log(import.meta.env.MODE)


window.addEventListener("load", () => {
  init();
}, false);

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}